const Localization = {
    General: {
        SuccessHeader: "Udført",
        SuccessAdd: "{context} blev tilføjet.",
        SuccessUpdate: "{context} blev opdateret.",
        SuccessDelete: "{context} blev slettet.",
        ErrorHeader: "Advarsel",
        NoteHeader: "Bemærk!",
        NoRowSelected: "Vælg venligst en række.",
        EditNoRowSelection: "Vælg venligst {context} du vil rette.",
        GridSettingsUpdated: "Grid indstillinger er blevet opdateret",
        SearchPostalCode: "Søg på postnummer",
        SearchCity: "Søg på bynavn",
        SearchRegion: "Søg på region",
        SearchCountry: "Søg på land",
        NoImageAvailable: "Ingen billede tilgængelig",
        UnauthorizedInPage: "Du har ikke adgang til denne side",
        PageNotFound: "Siden kunne ikke findes",
        InvalidEmailFormat: "Ugyldigt e-mail format",
        NotifyRequireFieldsNotFilled: "Der er obligatoriske felter, der skal udfyldes.",
        AlreadyInactive: "The {context} is already inactive",
        GoBackToLoginPage: "Gå tilbage til login-siden",
        EmptyList: "Ingen poster at vise",
        PleaseSelectItem: "Vælg venligst {context}",
        NoChangesMade: "Ingen ændringer foretaget",
        SingleRowEditOnly: "Du kan kun ændre en række ad gangen",
        PleaseSelectItemsToExport: "Vælg venligst poster at eksportere",
        GridResetFilter: "Nulstil Filter",
        Next: "Next",
        Previous: "Prev",
        InvalidFormat: "Ugyldigt {context} format"
    },
    Customer: {
        ErrorInvalidUserName: "Brugernavn skal være alfanumerisk",
        ErrorInvalidUserNameSystem: "Brugernavn for {system} skal være alfanumerisk",
        ErrorInvalidTelephone: "Telefonnummer må kun indeholde tal og plus(+) tegn",
        ErrorPleaseSelectWholeSaler: "Vælg venligt grossist",
        AddCustomerGroup: "Tilføj kundegruppe",
        Active: "Aktiv",
        Activate: "Aktiver",
        Inactive: "Inaktiv",
        Deactivate: "Deaktiver"
    },
    User: {
        AddUserGroup: "Tilføj brugergruppe",
        SelectUserToModify: "Vælg venligst en bruger der skal rettes",
        ExistingUsername: "Brugernavn er allerede taget",
        ExistingUsernameSystem: "Brugernavn for {system} er allerede taget",
        ExistingEmail: "Email allerede taget",
        ExistingUsernameById: "Brugernavn er allerede taget af bruger nr. {id}",
        ExistingEmailById: "Email er allerede taget af bruger nr. {id}"
    },
    Label: {
        PleaseSelectRecords: "Vælg venligst hvad du vil printe",
        NoRecordsForPrinting: "Vælg venligst hvad du vil printe",
        SearchCustomerGroup: "Vælg kundegruppe"
    },
    Machine: {
        MustSelectRoom: "Vælg venligst et rum",
        SelectMachineToDelete: "Vælg venligst modul du ønsker at slette",
        UnableToModifyIfContainsProduct: "Kan ikke ændre en hylde der indeholder et produkt."
    },
    Product: {
        PleaseSelectMachine: "Vælg venligst en automat",
        SuccessAddImage: "Billede blev tilføjet produktet",
        PleaseSelectImage: "Vælg venligst et billede",
        SuccessUnlinkedImage: "Billedet blev fjernet",
        ErrorMaxImageReached: "Det maksimum antal billeder der kan tilføjes er nået",
        SelectDepartment: "Vælg afdeling",
        Department: "Afdelinger",
        AllDepartment: "Alle afdelinger",
        PleaseSelectSupplier: "Vælg venligst et leverandør",
        SuccessCopyProduct: "Produkt er kopieret",
        AddProductGroup: "Tilføj produktgruppe",
        EditProductGroup: "Rediger produktgruppe",
        PleaseSelectProductGroup: "Vælg venligst produktgruppe",
        PleaseEnterGroupName: "Indtast navn på produktgruppen",
        SuccessLinkedImageToProductGroup: "Billede er linket til produktgruppen",
        PleaseSelectProductToModify: "Vælg venligst produkt du ønsker at ændre",
        PleaseSelectMachineToModify: "Vælg venligst modul du ønsker at ændre",
        UnmarkAsStandardLocationBeforeDelete: "Du kan ikke slette en lokation der er angivet som standard lokation",
        ProductNoAlreadyExist: "Vare nr. eksisterer allerede",
        EANNumbersOnly: "Tjek venligst ean nr., må kun indholde tal",
        ProductAlreadyInactive: "The product is already inactive", // FOR TRANSLATION
        UnableToDeleteProductContainsStock: "Unable to delete a product that contains a stock in a machine" // FOR TRANSLATION
    },
    Purchase: {
        ErrorSelectDeptMachSupplier: "Du skal vælge en afdeling, automat og leverandør for at forsætte",
        ErrorUnableDeleteAddState: "Du kan ikke slette en ordre mens du tilføjer produkter",
        PleaseSelectProduct: "Vælg venligst et produkt",
        ErrorNoProductsToAdd: "Ingen produkter og tilføje",
        InvalidQuantity: "Invalid quantity value"
    },
    Report: {
        ErrorRenamingReportNotExist: "Rapporten du prøver at omdøbe eksisterer ikke",
        SelectReportList: "Vælg rapport liste", 
        ReportType: "Rapport type",
        ReportAvailability: "Rapport tilgængelighed",
        ReportMenu: "Rapport menu",
        SelectReportList: "Vælg rappot liste",
        NoRecordsToExport: "Ingen poster at eksportere",
        PleaseSelectReportTemplate: "Vælg venligst en rapport skabelon"
    },
    Supplier: {
        SaveSupplierFirstBeforeSavingSettings: "Du skal gemme leverandør info, før du gemmer indstillinger"
    },
    Settings: {
        SmallBoxExceededTotalWeight: "Plastkasse S overstiger total vægt på hylde",
        MediumBoxExceededTotalWeight: "Plastkasse M overstiger total vægt på hylde",
        LargeBoxExceededTotalWeight: "Plastkasse L overstiger total vægt på hylde",
        WeightExceededTotalWeight: "Vægt overstiger maksimum",
        ToAndFromWeightAndToleranceRequired: "For at beregne en værdi, indtast venligst til og fra vægt samt tolerance i ovenstående tabel.",
        PlasticBinMustBeAboveZero: "{plastic_bin} værdi skal være over 0", //"Plastkasse L, værdi skal være over 0"
        ToleranceWeightExceedsTotalWeight: "Vægt af dette total til overstiger maksimum",
        ToWeightMustBeGreaterThanFromWeight: "To Weight must be greater than From Weight", // FOR TRANSLATION
        NoWeightToleranceForWeight: "For at beregne en værdi, indtast venligst til og fra vægt samt tolerance i ovenstående tabel",
        WeightToleranceNotSet: "Fra vægt samt tolerance i ovenstående tabel skal konfigurerest"
    },
    Message: {
        SuccessMoveMessageToArchive: "Besked er flyttet til arkiv",
        SuccessMoveMessageToInbox: "Besked er flyttet til inbox",
        SuccessMoveMessageToTrash: "Besked er flyttet til slettet",
        LoadingMessages: "Loader besked...",
        NoMessagesFound: "Ingen besked fundet.",
        SuccessSentMail: "Besked er sendt",
        Unread: 'Ulæst',
        Read: 'Læst',
        Sort: 'Sort by date',
        NoMessageSelected: 'Ingen meddelelser valgt',
        SuccessArchivedMessage: 'Meddelelsen blev arkiveret',
        MessageNotAllowedToBeModified: "Meddelelsen kan ikke ændres"
    },
    ForgotPassword: {
        EmailConfirmed: "Linket er sendt, tjek din e-mail for at nulstille din adgangskode.",
        SuccessfullyReset: "Din adgangskode blev nulstillet."
    },
    ChangePassword: {
        SuccessfullyChange: "Din adgangskode blev ændret."
    },
    VideoArchive: {
        SuccessfullyRequestForDownload: "Download af {context} udført",
        SuccessfullySavedAsFavorite: "Gemt som favorit",
        CanOnlyViewOneNote: "You can only view one note at a time." //FOR TRANSLATION
    },
    Tools: {
        SuccessImport: "Data import udført." 
    },
    ImportExport: {
        PleaseSelectAFile: "Vælg venligst en fil",
        NoRowsFound: "Ingen rækker fundet",
        NoErrorsFound: "Ingen fejl fundet"
    }
}


export default Localization;