import React, { Component } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Edit, Search, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Redirect } from 'react-router-dom';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs/src/uploader';
import { Internationalization, createElement, detach, EventHandler, select } from '@syncfusion/ej2-base';
import { getFileIcon, isImage } from '../utilities/Helper';
import BaseComponent from '../components/BaseComponent';
import AppContext from '../context/AppContext';
import Axios from 'axios';
import AppSettings from '../settings/AppSettings';

import SettingsService from '../services/SettingsService';
import HubFirmwareVersionService from '../services/HubFirmwareVersionService';
import SessionService from '../services/SessionService';
import Loading from '../components/Loading';
import Localization from '../utilities/Localization';
import MessageHelper from '../utilities/MessageHelper';
import { buildGridSettings } from '../utilities/Helper';

import '../styles/user.scss';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

class OilmatHubFirmwareVersions extends BaseComponent {
    moduleName = "Oilmat";
    pageName = "Oilmat Hub Firmware Versions";
    gridSettingsKey = "OilmatHubFirmwareVersions";
    hasDraggedColumn = false;
    gridSettings = '';

    constructor() {
        super();
        
        this.state = {
            loading: true,
            selectedId: 0,
            redirect: {
                to: '',
                isRedirect: false
            },
            data: {
                Hardware: "",
                Software: "",
                FileKey: ''
            }
        }

        this.pageSettings = {
            pageSize: 10,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.selectionSettings = {
            type: 'Single'
        }

        this.filterSettings = {
            type: 'Menu'
        };

        this.uploaderAsyncSettings = {
            saveUrl: `${AppSettings.API_URL}/file/uploadApk?isBinary=true`,
            removeUrl: `${AppSettings.API_URL}/file/removeApk?isBinary=true`
        };

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.dialogInstance.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];

        this.confirmDeleteButtons = [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    this.confirmDeleteDialog.hide();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    isPrimary: true,
                    content: 'OK'
                }
            }
        ];
        /* End Dialog options  */

        this.dialogOpen = this.dialogOpen.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.init = this.init.bind(this);
        this.onUploadSuccess = this.onUploadSuccess.bind(this);
        this.onCloseAddDialog = this.onCloseAddDialog.bind(this);
        this.onAddotaVersion = this.onAddotaVersion.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    
    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        this.init();

        const session = await SessionService.GetSessionInfo();
        this.uploaderAsyncSettings.saveUrl = this.uploaderAsyncSettings.saveUrl + `&access_token=${session.access_token}`;
        this.uploaderAsyncSettings.removeUrl = this.uploaderAsyncSettings.removeUrl + `&access_token=${session.access_token}`;
        
        const otaVersions = await HubFirmwareVersionService.GetList(source.token);
        this.data = otaVersions;

        const gridSettings = await SettingsService.GetGridSettings(this.gridSettingsKey, source.token);
        if (!gridSettings.HasError) {
            this.gridSettings = gridSettings.Settings;
        }

        this.setState({ loading: false }, () => {
            window.addEventListener('wheel', this.onWheelScroll);
        });
    }

    init() {
        this.toolbarOptions = [];
        this.defaultEditSettings = {};

        this.toolbarOptions.push({ text: 'Ny', tooltipText: 'Ny', id: 'add', prefixIcon: 'e-add' });
        this.toolbarOptions.push({text: 'Slette', tooltipText: 'Slette', id: 'delete', prefixIcon: 'e-delete'});
        this.defaultEditSettings = {
            allowDeleting: true,
            showDeleteConfirmDialog: true,
        };

        this.toolbarOptions.push({ text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' });
    }

    componentWillUnmount() {
        source.cancel();
        window.removeEventListener('wheel', this.onWheelScroll);
    }

    async refreshGrid(isActive = null) {
        const otaVersions = await HubFirmwareVersionService.GetList(source.token);
        this.otaVersionGridRef.dataSource = otaVersions;
        this.otaVersionGridRef.dataBind();
    }

    async onActionComplete(args) {
        if (args.cancel) return;
        if (args.requestType === 'paging') {
            if (this.otaVersionGridRef.pageSettings.pageSize === this.otaVersionGridRef.pageSettings.totalRecordsCount || this.otaVersionGridRef.pageSettings.pageSize === this.data.length) {
                document.querySelector("#otaversion-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, this.otaVersionGridRef.pageSettings.pageSize, this.otaVersionGridRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.otaVersionGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.otaVersionGridRef.columns.length - 1;
                for (let index = 0; index < this.otaVersionGridRef.columns.length; index++) {
                    let column = this.otaVersionGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.otaVersionGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
    }

    async clickHandler(args) {
        if (this.otaVersionGridRef && args.item.id === 'add') {
            this.addNewVersion.show();
        }
        else if (this.otaVersionGridRef && args.item.id === 'open') {
            const selectedRows = this.otaVersionGridRef.getSelectedRows();
            if (selectedRows.length <= 0) {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
            else {
                this.props.history.push(`/brugerkort/edit/${selectedRows[0].querySelector('td').innerHTML}`);
            }
        }
        else if (this.otaVersionGridRef && args.item.id === 'delete') {
            const selectedRows = this.otaVersionGridRef.getSelectedRecords();
            if (selectedRows.length <= 0) {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
            else {
                let buttons = null;
                let selectedRow = selectedRows[0];

                buttons = this.confirmDeleteButtons;

                buttons[1].click = async () => {
                    await this.deleteotaVersion(selectedRow.Id, false);
                }

                this.confirmDeleteDialog.content = 'Er du sikker på at du vil slette data?';

                this.confirmDeleteDialog.buttons = buttons;
                this.confirmDeleteDialog.show();
            }
        }
        else if (args.item.id === 'reset_filter') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                settings.filters = [];
                this.gridSettings = settings;
                const payload = {
                    Key: this.gridSettingsKey,
                    Settings: JSON.stringify(this.gridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.otaVersionGridRef.clearFiltering();
        }
    }

    async deleteotaVersion(id) {
        const result = await HubFirmwareVersionService.Delete(id, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Firmware Version"));
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage)
        }

        this.confirmDeleteDialog.hide();
        this.refreshGrid(true);
    }

    dialogOpen(header, content) {
        this.dialogInstance.header = header;
        this.dialogInstance.content = content;
        this.dialogInstance.show();
    }

    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onCreated() {
        if (this.gridSettings) {
            let settings = JSON.parse(this.gridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.otaVersionGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.otaVersionGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.otaVersionGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.otaVersionGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.otaVersionGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.otaVersionGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.otaVersionGridRef.columns.length - 1;    
                this.otaVersionGridRef.columns[lastColumnIndex].allowResizing = false;
                this.otaVersionGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.otaVersionGridRef.pageSettings.pageSize = this.otaVersionGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#otaversion-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.otaVersionGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.otaVersionGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, columns);
        this.gridSettings = tempGridSettings;
        const payload = {
            Key: this.gridSettingsKey,
            Settings: this.gridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.otaVersionGridRef.columns.length - 1;
            let totalColumnWidth = this.otaVersionGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.otaVersionGridRef.element.clientWidth - totalColumnWidth;
            this.otaVersionGridRef.columns[lastColumnIndex].width = this.otaVersionGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.otaVersionGridRef.refreshColumns();
        }
    }

    onWheelScroll = () => {
        let grid = document.getElementById('otaversion-grid');
        let toolbar = document.querySelector('#otaversion-grid.e-grid .e-toolbar');
        let tableHeader = document.querySelector('#otaversion-grid .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }
    }

    onUploadSuccess = (args) => {
        let fileName = '';
        let response = null;

        if (args.operation === 'upload') {
            if (args.e.target) {
                response = JSON.parse(args.e.target.response);
                fileName = response[0].Name;
            }
    
            let { data } = this.state;
            data.FileKey = response[0].Key;
        }
    }

    onCloseAddDialog() {
        let { data } = this.state;
        data.Hardware = "";
        data.Software = "";
        data.FileKey = "";
        this.setState({ data }, () => {
            this.addNewVersion.hide();
        });
    }

    async onAddotaVersion() {
        const { data } = this.state;

        const result = await HubFirmwareVersionService.Save(data, source.token);
        if (!result.HasError) {
            this.addNewVersion.hide();
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Firmware Version"));
            this.uploader.clearAll();
            this.refreshGrid();
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
    }

    onChange(args, name) {
        let { data } = this.state;
        data[args.target.name] = args.value;
        this.setState({ data });
    }

    render() {
        const { loading, redirect } = this.state;
        
        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className="content-pane-wrapper">
                <GridComponent id="otaversion-grid" ref={ref => this.otaVersionGridRef = ref } dataSource={this.data} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.toolbarOptions}
                    editSettings={this.defaultEditSettings} selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da"
                    gridLines="Both" toolbarClick={this.clickHandler.bind(this)} allowReordering={true} allowResizing={true} actionComplete={this.onActionComplete.bind(this)}
                    columnDragStart={this.onColumnDragStart.bind(this)} created={this.onCreated.bind(this)} resizeStop={this.onResizeStop.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective field="Hardware" headerText="Hardware" width='100' allowFiltering={true} />
                        <ColumnDirective field="Software" headerText="Software" width='100' allowFiltering={true} />
                        <ColumnDirective field="DateUploaded" headerText="Date Uploaded" width='100' allowFiltering={true} type='dateTime' format='dd/MM/yyyy hh:mm:ss' />
                        <ColumnDirective field="FileKey" headerText="File" width='100' allowFiltering={true} />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Edit, Search, Reorder, Resize]} />
                </GridComponent>
                <DialogComponent id="user-card-dialog" isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.dialogInstance = dialog} target='body' visible={false} showCloseIcon={true}
                    cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
                <div>
                    <DialogComponent id="user-card-dialog" isModal={true} buttons={this.confirmDeleteButtons} width='auto' ref={dialog => this.confirmDeleteDialog = dialog} target='body' visible={false} showCloseIcon={true}
                        header="Advarsel" cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />

                    <DialogComponent id="add-purchase-dialog" isModal={true} header="Add New Version" width='auto' ref={dialog => this.addNewVersion = dialog} 
                        target='body' visible={false} showCloseIcon={true} animationSettings={this.animationSettings} close={this.onCloseAddDialog} allowDragging={true} enableResize={true}>
                        <div className="dialog-content">
                            <div className="mb-20">
                                <TextBoxComponent value={this.state.data.Hardware} placeholder="Hardware" name="Hardware" floatLabelType="Auto" onChange={this.onChange} />
                            </div>
                            <div className="mb-20">
                                <TextBoxComponent value={this.state.data.Software} placeholder="Software" name="Software" floatLabelType="Auto" onChange={this.onChange} />
                            </div>
                            <div className="mb-20">
                                <label>File: </label>
                                <UploaderComponent id="fileUpload" type="file" ref={ref => this.uploader = ref} asyncSettings={this.uploaderAsyncSettings}
                                    success={this.onUploadSuccess} multiple={false} allowedExtensions=".bin">
                                </UploaderComponent>
                            </div>
                        </div>
                        <div className="dialog-footer">
                            <button className="e-control e-btn e-lib" onClick={this.onCloseAddDialog}>Annuller</button>
                            <button className="e-control e-btn e-lib e-primary" style={{width: 121}} onClick={this.onAddotaVersion}>Gem</button>
                        </div>
                    </DialogComponent>
                </div>
            </div>
        )
    }
}

OilmatHubFirmwareVersions.contextType = AppContext;

export default OilmatHubFirmwareVersions;