import HttpClient from '../utilities/HttpClient';

const API = {
    getRegions: `/settings/regions`
}

class RegionService {
    async GetRegion(cancelToken) {        
        return await HttpClient.Get(API.getRegions, null, cancelToken);
    }
}

const regionService = new RegionService();
export default regionService;