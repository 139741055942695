import HttpClient from '../../utilities/HttpClient';

const API = {
    getCustomers: `/customer`,
    getCustomer: `/customer/{customer_id}`,
    getByAccount: `/customer/{customer_id}/byAccount`,
    getUsers: `/customer/{customer_id}/users`,
    getChildCustomers: `/customer/{customer_id}/customers`,
    getAccountDepartments: `/customer/{customer_id}/departments`,
    getAccountPrimaryContacts: `/customer/{customer_id}/primaryContacts`,
    getReportTemplates: `/customer/{customer_id}/reportTemplates/{parentMenu_id}`,
    postCustomer:`/customer`,
    updateCustomer: `/customer`,
    deleteCustomer: `/customer/{customer_id}`,

    putCustomerUndo: `/customer/undo/{id}`,
    putCustomerRedo: `/customer/redo/{id}`,

    putExtendOilmatSubscription: `/customer/extend/{customer_id}/oilmat`,
    putExtendFlexmatSubscription: `/customer/extend/{customer_id}/flexmat/{module_id}`,

    updateAppAccess: `/customer/appAccess`
}

class CustomerService {
    async GetCustomers(cancelToken) {
        return await HttpClient.Get(API.getCustomers, null, cancelToken);
    }

    async GetCustomer(id, cancelToken) {
        const url = API.getCustomer.replace('{customer_id}', id);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async GetCustomerAccount(id, cancelToken) {
        const url = API.getByAccount.replace('{customer_id}', id);
        const result = await HttpClient.Get(url, null, cancelToken);

        if (result.HasError) {
            // Log error
            // result.ErrorMessage.message
            // result.ErrorMessage.config.url
            // result.ErrorMessage.config.method
            // result.ErrorMessage.config.data
        }

        return result;
    }

    async GetAccountDepartments(id, cancelToken) {
        const url = API.getAccountDepartments.replace('{customer_id}', id);
        const result = await HttpClient.Get(url, null, cancelToken);

        if (result.HasError) {
            // Log error
            // result.ErrorMessage.message
            // result.ErrorMessage.config.url
            // result.ErrorMessage.config.method
            // result.ErrorMessage.config.data
            return [];
        }
        else {
            return result.map(dept => {
                return {
                    CityId: dept.City ? dept.City.Id : null,
                    CityName: dept.City ? dept.City.ShortName : null,
                    RegionId: dept.Region ? dept.Region.Id : null,
                    RegionName: dept.Region ? dept.Region.Name : null,
                    CountryId: dept.Country ? dept.Country.Id : null,
                    CountryName: dept.Country ? dept.Country.Name : null,
                    PostalCodeId: dept.PostalCode ? dept.PostalCode.Id : null,
                    PostalCodeName: dept.PostalCode ? dept.PostalCode.Value : null,
                    DeliveryCityId: dept.DeliveryCity ? dept.DeliveryCity.Id : null,
                    DeliveryCityName: dept.DeliveryCity ? dept.DeliveryCity.ShortName : null,
                    DeliveryRegionId: dept.DeliveryRegion ? dept.DeliveryRegion.Id : null,
                    DeliveryRegionName: dept.DeliveryRegion ? dept.DeliveryRegion.Name : null,
                    DeliveryPostalCodeId: dept.DeliveryPostalCode ? dept.DeliveryPostalCode.Id : null,
                    DeliveryPostalCodeName: dept.DeliveryPostalCode ? dept.DeliveryPostalCode.Value : null,
                    DeliveryCountryId: dept.DeliveryCountry ? dept.DeliveryCountry.Id : null,
                    DeliveryCountryName: dept.DeliveryCountry ? dept.DeliveryCountry.Name : null,
                    ...dept,
                }
            });
        }
    }

    async GetAccountPrimaryContacts(id, cancelToken) {
        const url = API.getAccountPrimaryContacts.replace('{customer_id}', id);
        const result = await HttpClient.Get(url, null, cancelToken);
        let data = [];

        if (result.HasError) {
            // Log error
            // result.ErrorMessage.message
            // result.ErrorMessage.config.url
            // result.ErrorMessage.config.method
            // result.ErrorMessage.config.data
        }
        else {
            data = result.map(res => {
                return {
                    ...res,
                    Name: res.Account ? res.Account.FullName : '',
                    Email: res.Account ? res.Account.Email : '',
                    PhoneNumber: res.Account ? res.Account.PhoneNumber : '',
                    DepartmentName: res.Department ? res.Department.Name : '',
                }
            });
        }

        return data;
    }

    async GetChildCustomers(id, param, cancelToken) {
        const url = API.getChildCustomers.replace('{customer_id}', id);
        const result = await HttpClient.Get(url, param, cancelToken);

        if (result.HasError) {
            return [];
        }

        let data = [];
        data = result.map(item => {
            return {
                Id: item.Id,
                ParentId: item.ParentId,
                AccountId: item.AccountId,
                CustomerGroupId: item.CustomerGroup.Id,
                CustomerGroupName: item.CustomerGroup.Name,
                WholesalerId: item.Wholesaler ? item.Wholesaler.Id : null,
                DistributorId: item.Distributor ? item.Distributor.Id : null,
                EndUserId: item.EndUserId,
                City: item.City,
                CityId: item.City ? item.City.Id : null,
                CompanyName: item.CompanyName,
                Country: item.Country,
                CustomerNo: item.CustomerNo,
                Machines: item.Machines,
                Region: item.Region,
                RegionId: item.Region ? item.Region.Id : null,
                Country: item.Country,
                CountryId: item.Country ? item.Country.Id : null,
                Street: item.Street,
                VatNo: item.VatNo,
                PostalCode: item.PostalCode ? item.PostalCode.Value : null,
                PostalCodeId: item.PostalCode ? item.PostalCode.Id : null,
                DistributorName: item.Distributor && item.Distributor.Id != item.Id ? item.Distributor.CompanyName: '',
                WholesalerName: item.Wholesaler && item.Wholesaler.Id != item.Id ? item.Wholesaler.CompanyName: '',
                EndUserName: item.EndUser && item.EndUser.Id != item.id ? item.EndUser.CompanyName: '',
                Username: item.Account ? item.Account.Username : '',
                IntUserName: item.Account ? item.Account.IntUserName : '',
                FmUserName: item.Account ? item.Account.FmUserName : '',
                FirstName: item.Account ? item.Account.FirstName : '',
                MiddleName: item.Account ? item.Account.MiddleName : '',
                LastName: item.Account ? item.Account.LastName : '',
                Email: item.Account ? item.Account.Email: '',
                FullName: item.Account ? item.Account.FullName: '',
                PhoneNumber: item.Account ? item.Account.PhoneNumber: '',
                InUse: item.InUse,
                Account: item.Account,
                ContactMail: item.ContactMail,
                OilmatCustomer: item.OilmatCustomer,
                FlexmatCustomer: item.FlexmatCustomer,
                CreationDate: item.CreationDate
            }
        })

        return data;
    }

    async AddUpdateCustomer(state, data, cancelToken) {
        const payload = {
            CompanyName: data.CompanyName,
            Street: data.Street,
            PostalCodeId: parseInt(data.PostalCodeId),
            Country: data.Country,
            VatNo: data.VatNo,
            ContactMail: data.ContactMail,
            ParentId: parseInt(data.ParentId),
            CustomerGroupId: parseInt(data.CustomerGroupId),
            DistributorId: parseInt(data.DistributorId),
            WholesalerId: parseInt(data.WholesalerId),
            Account: {
                IntUserName: data.IntUserName,
                FmUserName: data.FmUserName,
                Active: data.Active,
                FirstName: data.FirstName,
                MiddleName: data.MiddleName,
                LastName: data.LastName,
                Email: data.Email,
                PhoneNumber: data.PhoneNumber,
                AccessControlPanel: data.AccessControlPanel,
                TwoFactorEnabled: data.TwoFactorEnabled,
                AppAccess: data.AppAccess
            },
            BusinessCategories: data.BusinessCategories
        };

        switch(state) {
            case 'add':
                return await HttpClient.Post(API.postCustomer, payload, cancelToken);
            case 'update':
                payload.Id = data.Id;
                payload.Account.Id = data.AccountId;
                return await HttpClient.Put(API.updateCustomer, payload, cancelToken);
            default:
                return null;
        }
    }

    async UpdateAppAccess(id, payload, cancelToken) {
        return await HttpClient.Post(API.updateAppAccess, payload, cancelToken);
    }

    async DeleteCustomer(id, params, cancelToken) {
        const url = API.deleteCustomer.replace('{customer_id}', id);
        return await HttpClient.Delete(url, params, cancelToken);
    }

    async GetUsers(id, param, cancelToken) {
        const url = API.getUsers.replace('{customer_id}', id);
        let result = await HttpClient.Get(url, param, cancelToken);
        
        if (result.HasError) {
            // Log error
            // result.ErrorMessage.message
            // result.ErrorMessage.config.url
            // result.ErrorMessage.config.method
            // result.ErrorMessage.config.data
        }
        else {
            result = result.map(data => {
                return {
                    ...data,
                    Name: data.Account ? data.Account.FullName : '',
                    PhoneNumber: data.Account ? data.Account.PhoneNumber : '',
                    Email: data.Account ? data.Account.Email : '',
                    Initials: data.Account ? data.Account.Initials : '',
                    Username: data.Account ? data.Account.Username : '',
                    IntUserName: data.Account ? data.Account.IntUserName : '',
                    FmUserName: data.Account ? data.Account.FmUserName : '',
                    OmUserName: data.Account ? data.Account.OmUserName : '',
                    UserGroupId: data.UserGroup ? data.UserGroup.Id : '',
                    UserGroupName: data.UserGroup ? data.UserGroup.Name : '',
                    DepartmentId: data.Department ?  data.Department.Id : '',
                    DepartmentName: data.Department ?  data.Department.Name : '',
                    IsDeactivated: !data.Account.Active
                }
            });
        }

        return result;
    }

    async UndoCustomer(id, cancelToken) {
        const url = API.putCustomerUndo.replace('{id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async RedoCustomer(id, cancelToken) {
        const url = API.putCustomerRedo.replace('{id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async GetReportTemplates(customerId, parentMenuId, cancelToken) {
        const url = API.getReportTemplates.replace('{customer_id}', customerId).replace('{parentMenu_id}', parentMenuId);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async UpdateOilmatSubscription(customerId, cancelToken) {
        const url = API.putExtendOilmatSubscription.replace('{customer_id}', customerId);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async UpdateFlexmatSubscription(customerId, moduleId, cancelToken) {
        const url = API.putExtendFlexmatSubscription.replace('{customer_id}', customerId).replace('{module_id}', moduleId);
        return await HttpClient.Put(url, null, cancelToken);
    }
}

const customerService = new CustomerService();
export default customerService;