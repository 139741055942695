import React from 'react';
// import { TabComponent, TabItemDirective, TabItemsDirective, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Redirect } from 'react-router-dom';

import BaseComponent from '../components/BaseComponent';
import OilmatAppVersions from '../pages/OilmatAppVersions';
import OilmatFirmwareVersions from '../pages/OilmatFirmwareVersions';
import OilmatHubFirmwareVersions from '../pages/OilmatHubFirmwareVersions';
import Loading from '../components/Loading';
import AppContext from '../context/AppContext';

export class OilmatVersions extends BaseComponent {
    moduleName = "System indstillinger";
    pageName = "OilMat Versions";
    
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            defaultSelectedTab: 0,
            permissions: [],
            redirect: {
                to: '',
                isRedirect: false
            },
            currentTabSelected: 0
        }
        
        this.tabHeader = [
            { text: "Firmware OilMat Pump Version", url: "/system-indstillinger/oilmat/fw" },
            { text: "Firmware OilMat Hub Version", url: "/system-indstillinger/oilmat/hubFw" }, 
            { text: "Sofware App Version", url: "/system-indstillinger/oilmat/sw" }
        ];

        this.dialogOpen = this.dialogOpen.bind(this);
        this.onOpenProduct = this.onOpenProduct.bind(this);
        this.onTabSelected = this.onTabSelected.bind(this);
        this.pumpOtaTab = this.pumpOtaTab.bind(this);
        this.hubOtaTab = this.hubOtaTab.bind(this);
        this.appApkTab = this.appApkTab.bind(this);
        
        this.defaultSelectedTab = 0;
        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */
    }

    componentDidMount() {
        super.componentDidMount();
        const { location } = this.props;
        let { currentTabSelected } = this.state;
        let headerText = '';
        
        if (location) {
            if (location.state) {
                if (location.state.tab) {
                    let header = this.tabHeader.find(x => x.text === location.state.tab);
                    currentTabSelected = this.tabHeader.indexOf(header);
                    headerText = header.text;
                }
            }
            else {
                let header = this.tabHeader.find(x => x.url === location.pathname);
                currentTabSelected = this.tabHeader.indexOf(header);
                headerText = header.text;
            }
        }

        const { siteRoutes } = this.context;
        let hasProductAccess = false;
        let groupsRoute = [];
        let permissions = [];
        
        // get groups
        // if (siteRoutes) {
        //     let route = null;
        //     if (siteRoutes.some(x => x.Name === "Produkt")) {
        //         route = siteRoutes.find(x => x.Name === "Produkt");
        //         // if (route.MenuItems.some(a => a.Name === "Produkt Grupper")) {
        //         //     groupsRoute.push('Produkt Grupper');
        //         //     permissions.push(route.MenuItems.find(a => a.Name === "Produkt Grupper"));
        //         // }

        //         if (route.MenuItems.some(a => a.Name === "Produktkort")) {
        //             let productCard = route.MenuItems.find(a => a.Name === "Produktkort");
        //             if (productCard.MenuTabs.some(f => f.Name === "Pris")) {
        //                 hasProductAccess = true;
        //             }
        //         }
        //     }
        // }

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (super.hasMenuItem("OilMat")) {
            groupsRoute = [];
            groupsRoute.push('Firmware OilMat Pump Version');
            groupsRoute.push('Firmware OilMat Hub Version');
            groupsRoute.push('Sofware App Version');
        }
        else {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        this.setState({ loading: false, currentTabSelected, groupsRoute, permissions, hasProductAccess }, () => {
            let headerElement = document.querySelector('.module-header > h1');
            if (headerElement) {
                headerElement.innerHTML = headerText;
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { location } = this.props;
        const { location: prevLocation } = prevProps;

        if (location && prevLocation) {
            if (!location.state) {
                if (location.pathname !== prevLocation.pathname) {
                    let header = this.tabHeader.find(x => x.url === location.pathname);
                    let currentTabSelected = this.tabHeader.indexOf(header);
                    let headerText = header.text;
                    this.setState({ currentTabSelected }, () => {
                        let headerElement = document.querySelector('.module-header > h1');
                        headerElement.innerHTML = headerText;
                    });
                }
            }
        }
    }
    
    tabSelected(args) {
        const selectedTab = this.tabHeader[args.selectedIndex];
        let headerElement = document.querySelector('.module-header > h1');
        headerElement.innerHTML = selectedTab.text;
        this.props.history.push({
            pathname: selectedTab.url,
            state: {
                tab: selectedTab.text
            }
        });
    }

    onTabSelected(index) {
        const selectedTab = this.tabHeader[index];
        let headerElement = document.querySelector('.module-header > h1');
        headerElement.innerHTML = selectedTab.text;
        this.props.history.push({
            pathname: selectedTab.url,
            state: {
                tab: selectedTab.text
            }
        });
        this.setState({ currentTabSelected: index });
    }

    onOpenProduct(url) {
        this.props.history.push(url);
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    pumpOtaTab() {
        return <OilmatFirmwareVersions onNotify={this.dialogOpen} />
    }

    appApkTab() {
        return <OilmatAppVersions onNotify={this.dialogOpen} />
    }

    hubOtaTab() {
        return <OilmatHubFirmwareVersions onNotify={this.dialogOpen} />
    }

    render() {
        const { loading, defaultSelectedTab, groupsRoute, redirect, currentTabSelected } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className='content-pane-wrapper'>
                <div className="module-header">
                    <h1>{this.tabHeader[defaultSelectedTab].text}</h1>
                </div>
                <div className='control-section tab-control'>
                    <Tabs id="account-tab" className="c-tab" selectedIndex={currentTabSelected} onSelect={index => this.onTabSelected(index)} disabledTabClassName="c-tabs__tab--disabled">
                        <TabList className="c-tab__list">
                            {super.hasMenuItemTabAccess("OilMat", "Firmware OilMat Pump Version") && 
                                <Tab className={`c-tabs__tab ${groupsRoute.some(g => g === this.tabHeader[0].text) ? '' : 'hidden'}`} disabled={!groupsRoute.some(g => g === this.tabHeader[0].text)} selectedClassName="c-tabs__tab--selected">{this.tabHeader[0].text}</Tab>
                            }
                            {super.hasMenuItemTabAccess("OilMat", "Firmware OilMat Hub Version") && 
                                <Tab className={`c-tabs__tab ${groupsRoute.some(g => g === this.tabHeader[1].text) ? '' : 'hidden'}`} disabled={!groupsRoute.some(g => g === this.tabHeader[1].text)} selectedClassName="c-tabs__tab--selected">{this.tabHeader[1].text}</Tab>
                            }
                            {super.hasMenuItemTabAccess("OilMat", "Sofware App Version") &&
                                <Tab className={`c-tabs__tab ${groupsRoute.some(g => g === this.tabHeader[2].text) ? '' : 'hidden'}`} disabled={!groupsRoute.some(g => g === this.tabHeader[2].text)} selectedClassName="c-tabs__tab--selected">{this.tabHeader[2].text}</Tab>
                            }
                        </TabList>
                        {super.hasMenuItemTabAccess("OilMat", "Firmware OilMat Pump Version") && 
                            <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                                {this.pumpOtaTab()}
                            </TabPanel>
                        }
                        {super.hasMenuItemTabAccess("OilMat", "Firmware OilMat Hub Version") && 
                            <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                                {this.hubOtaTab()}
                            </TabPanel>
                        }
                        {super.hasMenuItemTabAccess("OilMat", "Sofware App Version") && 
                            <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                                {this.appApkTab()}
                            </TabPanel>
                        }
                    </Tabs>
                </div>
                <DialogComponent id="system-group-dialog" isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body'
                    visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} />
            </div>
        )
    }
}

OilmatVersions.contextType = AppContext;
export default OilmatVersions;