import React, { Component } from 'react';
import { SidebarComponent, MenuComponent } from '@syncfusion/ej2-react-navigations';
// import { enableRipple } from '@syncfusion/ej2-base';
import { Switch, Route, Redirect } from 'react-router-dom';
import Axios from 'axios';
// import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import AutoComplete from 'react-autocomplete';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ToastContainer } from 'react-toastify';

import './components/Globalization';
import AppContext from './context/AppContext';
import { logger } from './logger/Logger';
import AuthenticationService from './services/AuthenticationService';
import CustomerService from './services/Customer/CustomerService';
// import UserService from './services/User/UserService';
import MenuService from './services/MenuService';
// import SettingsService from './services/SettingsService';
import SessionService from './services/SessionService';

import { StorageKeys } from './settings/StorageKeys';

/* PAGES */
import Messages from './pages/Messages';
import Labels from './pages/Label/Labels';
import LabelPrinter from './pages/Label/LabelPrinter';
import MachineList from './pages/Machine/MachineList';
import Machine from './pages/Machine/Machine';
import MyAccount from './pages/MyAccount/MyAccount';
import Product from './pages/Product/Product';
import ProductList from './pages/Product/ProductList';
import PurchaseList from './pages/Purchase/PurchaseList';
import PurchaseOrder from './pages/Purchase/PurchaseOrder';
import PurchaseSetting from './pages/Purchase/PurchaseSettings';
import Reports from './pages/Report/Reports';
import UserDefinedReports from './pages/Report/UserDefinedReports';
import SupplierList from './pages/Supplier/SupplierList';
import Supplier from './pages/Supplier/Supplier';
import UserList from './pages/User/UserList';
import UserCard from './pages/User/UserCard';
import Customer from './pages/Customer/Customers';
import SystemGroups from './pages/SystemSettings/SystemGroups';
import FileArchive from './pages/FileArchive';
import Units from './pages/SystemSettings/Units';
import Weight from './pages/SystemSettings/Weight';
import NoPermission from './pages/NoPermission';
import NotFound from './pages/NotFound';
import Loading from './components/Loading';
import Integration from './pages/SystemSettings/Integration';
import MailSettings from './pages/SystemSettings/MailSettings';
import ChangePassword from './pages/Account/ChangePassword';
import Register from './pages/SystemSettings/Register';
import VideoArchive from './pages/VideoArchive';
import UserTools from './pages/Tools/UserTools';
import ProductTools from './pages/Tools/ProductTools';
import ImportExportTool from './pages/Tools/ImportExport';
import StockRegulationTools from './pages/Tools/StockRegulationTools';
import ErrorBoundary from './components/ErrorBoundary';
import OilmatVersions from './pages/OilmatVersions';
import OilmatProduct from './pages/Product/OilmatProduct';
import { getPage, MapEntityToPage } from './utilities/SearchHelper';

import companyLogo from './assets/logo_ilx.png';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-navigations/styles/material.css";

import './styles/material.scss';
import './styles/base.scss';
import './styles/custom.scss';
import 'react-toastify/dist/ReactToastify.css';
import HelpArticles from './pages/HelpArticles';
import OilmatIntegrationSettings from './pages/SystemSettings/OilmatIntegrationSettings';

// enableRipple(true);

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

// const defaultRoute = [{ text: 'Beskeder', urlRoute: '/beskeder', iconCss: 'c-icon-messages-inactive', childRoutes: [] }];
const restrictedRoutes = [
    { text: 'Beskeder', urlRoute: '/beskeder', iconCss: 'c-icon-messages-inactive', childRoutes: [] },
    { text: 'Min Konto', urlRoute: '/min-konto', iconCss: 'c-icon-myaccount-inactive', childRoutes: [] },
    { text: 'Kunde', iconCss: 'c-icon-customers-inactive', parentRoute: '/kunder', childRoutes: [],
        items: [
            { text: 'Kundeliste', urlRoute: '/kunder' },
            { text: 'Kunde Grupper', urlRoute: '/system-indstillinger/grupper/kundegruppe' },
        ]
    },
    {  text: 'Bruger', iconCss: 'c-icon-users-inactive', parentRoute: '/bruger', childRoutes: [],
        items: [
            { text: 'Brugerliste', urlRoute: '/bruger-liste' },
            { text: 'Brugergrupper', urlRoute: '/system-indstillinger/grupper/brugergrupper' }
        ]
    },
    { text: 'Produkt', iconCss: 'c-icon-productlist-inactive', parentRoute: '/produkt', childRoutes: [],
        items: [
            { text: 'Produktliste', urlRoute: '/produktliste' },
            { text: 'Produkt Grupper', urlRoute: '/system-indstillinger/grupper/produkt-grupper' },
        ],
        displayText: 'FlexMat Produkt'
    },
    { text: 'Oilmat Produkt', iconCss: 'c-icon-productlist-inactive', urlRoute: '/oilmat-produkt', childRoutes: [], displayText: 'OilMat Produkt' },
    { text: 'Leverandør', iconCss: 'c-icon-suppliers-inactive', parentRoute: '/leverandor', childRoutes: [],
        items: [
            { text: 'Leverandørliste', urlRoute: '/leverandor/leverandorliste'}
        ]
    },
    { text: 'Automater', urlRoute: '/automatliste', iconCss: 'c-icon-machinemodules-inactive', childRoutes: [ '/automater' ] },
    { text: 'Indkøb', iconCss: 'c-icon-purchase-inactive', childRoutes: ['/indkobsordrer','/indkobs-indstillinger','/indkob/ordre-detaljer'],
        items: [
            {text: 'Indkøbsordre', urlRoute: '/indkobsordrer'},
            {text: 'Indkøbsindstillinger', urlRoute: '/indkobs-indstillinger'}
        ]
    },
    { text: 'Rapporter', iconCss: 'c-icon-reports-inactive', childRoutes: ['/rapporter'],
        items: [
            { text: 'Favoritter', urlRoute: '/rapporter/favoritter' },
            { separator: true},
            { text: 'Automater', urlRoute: '/rapporter/automater' },
            { text: 'Brugere', urlRoute: '/rapporter/brugere' },
            { text: 'Forbrug', urlRoute: '/rapporter/forbrug' },
            { text: 'Indkøb', urlRoute: '/rapporter/indkob' },
            { text: 'Kunder', urlRoute: '/rapporter/kunder' },
            { text: 'Lager', urlRoute: '/rapporter/lager' },
            { text: 'Leverandører', urlRoute: '/rapporter/leverandorer' },
            { text: 'Produkt', urlRoute: '/rapporter/produkt' },
            { separator: true},
            { text: 'Bruger Defineret', urlRoute: '/rapporter/bruger-defineret' },
        ]
    },
    { text: 'Etiketter', urlRoute: '/etiketter', iconCss: 'c-icon-labels-inactive', childRoutes: [] },
    { text: 'Fil Arkiv', urlRoute: '/fil-arkiv', iconCss: 'c-icon-archive-inactive', childRoutes: [] },
    { text: 'Video Arkiv', urlRoute: '/video-arkiv', iconCss: 'c-icon-videos-inactive', childRoutes: [] },
    { text: 'System indstillinger', iconCss: 'c-icon-systemsettings-inactive', parentRoute: '/system-indstillinger', childRoutes: [],
        items: [
            {text: 'Integration', urlRoute: '/system-indstillinger/integration' },
            {text: 'Grupper', urlRoute: '/system-indstillinger/grupper/kundegruppe' },
            {text: 'Enheder', urlRoute: '/system-indstillinger/enheder' },
            {text: 'Registre', urlRoute: '/system-indstillinger/registres' },
            {text: 'Vægt', urlRoute: '/system-indstillinger/vægt' },
            {text: 'Mailindstillinger', urlRoute: '/system-indstillinger/mailindstillinger' },
            {text: 'OilMat', urlRoute: '/system-indstillinger/oilmat/fw' },
            {text: 'OilMat Integration', urlRoute: '/system-indstillinger/oilmat-integration' }
        ]
    },
    { text: 'Værktøjer', urlRoute: '/værktøjer', iconCss: 'c-icon-tools-inactive', parentRoute: '/værktøjer', childRoutes: [],
        items: [
            {text: 'Brugere', urlRoute: '/værktøjer/brugere' },
            { 
                text: 'Produkter', urlRoute: '',
                items: [
                    {text: 'Masse Redigering', urlRoute: '/værktøjer/produkter/masse-redigering' },
                    {text: 'Lager Regulering', urlRoute: '/værktøjer/produkter/lager-regulering' },
                ]
            },
            {text: 'Import / Export', urlRoute: '/værktøjer/import-export' },
        ] 
    },
    { text: 'Help', iconCss: 'c-icon-help-inactive', parentRoute: '/help', childRoutes: [],
        items: [
            {text: 'Intralogix', urlRoute: '/help/intralogix' },
            {text: 'Flexmat', urlRoute: '/help/flexmat' },
        ] 
    }
]

const colorThemes = [ "#007bee", "#00a266", "#0d94ce", "#db5d31", "#738540", "#b1649a", "#877b51", "#d95a78", "#9566be", "#779188" ];

class Main extends Component {
    isOtherCustomer = false;
    CustomerId = null;

    constructor() {
        super();

        this.state = {
            loading: true,
            userId: null,
            searchString: '',
            searchItems: [],
            searchContext: 1,
            siteRoutes: [],
            colorTheme: ''
        }

        this.dataMenuItems = [];

        this.AccountMenuItem = [
            {
                name: 'User',
                values: [
                    { id: 'signout', name: 'Sign out' },
                ]
            }
        ];

        this.menuFields = {
            dataSource: this.dataMenuItems, 
            id: 'id', parentID: 'pid', text: 'name', 
            hasChildren: 'hasChild' 
        };

        this.autoCompleteFields = {
            value: "ProductNoAndDescription"
        };

        this.searchContextFields = { text: 'Key', value: 'Value' };

        this.menuTreeView = null;
        this.sidebar = null;
        this.menuObj = null;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.setActiveMenu = this.setActiveMenu.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.accountMenuTemplate = this.accountMenuTemplate.bind(this);
        this.searchFocused = this.searchFocused.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onSearchSelect = this.onSearchSelect.bind(this);
        this.updateUserMenu = this.updateUserMenu.bind(this);
    }
    
    async componentDidMount() {
        // Check Logged in as
        let loggedInCustomer = localStorage.getItem(StorageKeys.temp_LoggedInAs_Token_Storage);

        if (loggedInCustomer) {
            sessionStorage.setItem(StorageKeys.LoggedInAs_Key, loggedInCustomer);
            localStorage.removeItem(StorageKeys.temp_LoggedInAs_Token_Storage);
        }
        let loggedinAsKey = sessionStorage.getItem(StorageKeys.LoggedInAs_Key);
        if (loggedinAsKey) {
            this.isOtherCustomer = true;
        }

        await this.updateUserMenu();
    }

    async updateUserMenu() {
        const user = await AuthenticationService.getUser();
        if (user) {
            let name = '';
            let permittedRoutes = [];
            let sessionInfo = await SessionService.GetSessionInfo();
            this.CustomerId = sessionInfo.Id;

            if (sessionInfo.userType === 'customer') {
                if (this.isOtherCustomer) {
                    const creator = await CustomerService.GetCustomerAccount(sessionInfo.CreatedById, source.token);
                    name = `${sessionInfo.Account.FullName} / ${creator.Account.FullName} / ${creator.Account.Username}`;
                }
                else {
                    name = `${sessionInfo.Account.FullName} / ${sessionInfo.Account.Username}`;
                }
            }
            else if (sessionInfo.userType === 'user') {
                name = `${sessionInfo.Account.FullName} / ${sessionInfo.Account.Username}`;
            }

            sessionInfo.GroupMenu.forEach(menu => {
                let tempRoute = {};

                if (restrictedRoutes.some(r => r.text === menu.Name)) {
                    let accessibleRoute = restrictedRoutes.find(r => r.text === menu.Name);
                    accessibleRoute.text = accessibleRoute.displayText ? accessibleRoute.displayText : accessibleRoute.text;

                    if (accessibleRoute.items && accessibleRoute.items.length > 0) {
                        Object.assign(tempRoute, accessibleRoute);
                        tempRoute.items = [];
                        accessibleRoute.items.forEach(child => {
                            if (child.separator) {
                                tempRoute.items.push(child);
                            }

                            let childText = child.text;

                            if (menu.Name === "Rapporter") {
                                let tempItem = menu.MenuItems.find( mi => mi.Name === childText);
                                if (!tempItem) return;

                                if (childText !== "Bruger Defineret") {
                                    child.urlRoute = '';
                                }

                                if (tempItem.ReportTemplates && tempItem.ReportTemplates.length > 0) {
                                    child.items = [];
                                    child.items = tempItem.ReportTemplates.map(rep => {
                                        let param = `${rep.Id},${rep.ParentMenuItemId},${rep.ReportType}`;
                                        param = btoa(param);
                                        return {
                                            tid: rep.Id + 1000,
                                            text: rep.Name,
                                            urlRoute: `/rapporter/${tempItem.Name.toLowerCase().replace(/[ ]/g,'-')}?r=${param}`
                                        }
                                    });
                                }
                                else {
                                    child.items = [];
                                }
                                tempRoute.items.push(child);
                            }
                            else if (menu.Name === "Værktøjer") {
                                let tempItem = menu.MenuItems.find( mi => mi.Name === childText);
                                if (!tempItem) return;
                                
                                if (childText === "Produkter") {
                                    child.items = [];
                                    child.items = tempItem.MenuTabs.map(m => {
                                        let urlRoute = '/værktøjer/produkter/masse-redigering';

                                        if (m.Name.toLowerCase() === "lager redigering") {
                                            urlRoute = '/værktøjer/produkter/lager-regulering';
                                        }

                                        return {
                                            tid: m.Id,
                                            text: m.Name,
                                            urlRoute
                                        }
                                    });
                                }
                                
                                tempRoute.items.push(child);
                            }
                            else {
                                if (childText === "Kunde Grupper" || childText === "Brugergrupper" || childText === "Produkt Grupper") {
                                    if (sessionInfo.GroupMenu.some(s => s.Name === "System indstillinger" && s.MenuItems.some(mi => mi.Name === "Grupper"))) {
                                        let tempItem = menu.MenuItems.find( mi => mi.Name === childText);
                                        if (tempItem) {
                                            child.tid = tempItem.Id;
                                            tempRoute.items.push(child);
                                        }
                                    }
                                }
                                else if (menu.MenuItems.some( mi => mi.Name === childText)) {
                                    let tempItem = menu.MenuItems.find( mi => mi.Name === childText);
                                    child.tid = tempItem.Id;
                                    tempRoute.items.push(child);
                                }
                            }
                        });

                        if (menu.Name === "Kunde" || menu.Name === "Bruger" || menu.Name === "Produkt") {
                            if (sessionInfo.GroupMenu.some(s => s.Name === "System indstillinger" && s.MenuItems.some(mi => mi.Name === "Grupper"))) {
                                let child = null;
                                switch (menu.Name) {
                                    case "Kunde":
                                        child = { tid: 202, text: 'Kunde Grupper', urlRoute: '/system-indstillinger/grupper/kundegruppe' };
                                        tempRoute.items.push(child);
                                        break;
                                    case "Bruger":
                                        child = { tid: 205, text: 'Brugergrupper', urlRoute: '/system-indstillinger/grupper/brugergrupper' };
                                        tempRoute.items.push(child);
                                        break;
                                    case "Produkt":
                                        child = { tid: 208, text: 'Produkt Grupper', urlRoute: '/system-indstillinger/grupper/produkt-grupper' };
                                        tempRoute.items.push(child);
                                        break;
                                }
                            }
                        }

                        permittedRoutes.push(tempRoute);
                    }
                    else {
                        if (!permittedRoutes.some(x => x.text === accessibleRoute.text)) {
                            accessibleRoute.tid = menu.Id;
                            permittedRoutes.push(accessibleRoute);
                        }
                    }
                }
            });

            let menuRoute = restrictedRoutes.find(r => r.text === 'Help')
            if (menuRoute) {
                permittedRoutes.push(menuRoute);
            }
            
            this.dataMenuItems = permittedRoutes;
            if (this.menuObj) {
                this.menuObj.items = permittedRoutes;
                this.menuObj.refresh();
            }
            
            this.menuFields.dataSource = permittedRoutes;
            this.AccountMenuItem[0].name = name;
            this.AccountMenuItem[0].values[0].name = name;
            
            const searchContexts = await MenuService.GetSearchContexts(source.token);
            this.searchContexts = searchContexts;

            const entityTypes = await MenuService.GetEntityTypes(source.token);
            this.entityTypes = MapEntityToPage(entityTypes);
            
            let availableColorTheme = localStorage.getItem("color_themes_avail");
            let colorTheme = '';

            if (!availableColorTheme) {
                availableColorTheme = colorThemes;
                localStorage.setItem("color_themes_avail", colorThemes);
            }
            else {
                availableColorTheme = availableColorTheme.split(',');
                if (availableColorTheme.length <= 0) {
                    availableColorTheme = colorThemes;
                    localStorage.setItem("color_themes_avail", colorThemes);
                }
            }

            if (this.isOtherCustomer) {
                let sessionSettings = sessionStorage.getItem("cust_site_settings");
                let settings = {};

                if (sessionSettings) {
                    settings = JSON.parse(sessionSettings);
                    colorTheme = settings.colorTheme;
                }
                else {
                    colorTheme = availableColorTheme[0];
                    settings.colorTheme = colorTheme;
                    sessionStorage.setItem("cust_site_settings", JSON.stringify(settings));

                    availableColorTheme.shift();
                    localStorage.setItem("color_themes_avail", availableColorTheme);
                }
            }

            this.setState({ loading: false, siteRoutes: sessionInfo.GroupMenu }, () => {
                setTimeout(() => {
                    if (this.isOtherCustomer) {
                        if (colorTheme) {
                            let header = document.querySelector(".app-main .header");
                            let sidebar = document.querySelector("#sidebar-menu");
        
                            if (header) {
                                header.style.backgroundColor = colorTheme;
                                header.classList.add('light-text');
                            }
        
                            if (sidebar) {
                                sidebar.style.backgroundColor = colorTheme;
                                sidebar.querySelector('ul').style.backgroundColor = colorTheme; // #sidebar-menu ul
                                sidebar.classList.add('light-text');
                            }
                        }
                    }
                }, 300);
            });
        }
        else {
            AuthenticationService.login();
        }
    }

    toggleSidebar() {
        this.sidebar.toggle();
    }

    nodeMenuSelected(args) {
        const url = args.item.urlRoute;
        if (url) {
            if (url.includes('rapporter')) {
                this.props.history.push(url, {
                    parentMenuId: args.item.tid
                });
            }
            else {
                this.props.history.push(url);
            }
        }
    }

    onLogout(args) {
        AuthenticationService.logout();
    }

    setActiveMenu(pathname) {
        setTimeout(() => {
            // Set active menu
            const sidebar = document.getElementById('sidebar-menu');
            if(sidebar === null) return false;

            let activeMenu = this.dataMenuItems.find(item => item.urlRoute === pathname || pathname.startsWith(item.parentRoute) || item.childRoutes.some(x => pathname.includes(x)));
            
            if (pathname.startsWith('/rapporter')) {
                activeMenu = this.dataMenuItems.find(item => item.text === "Rapporter");
            }
            
            if(typeof activeMenu === 'undefined') return false;
            
            let listItem = sidebar.querySelectorAll('.sidebar-tree-menu > ul > li.e-menu-item');
            if(typeof listItem === 'undefined') return false;

            listItem.forEach(item => {
                let icon = item.querySelector('span.e-menu-icon');
                let currentIconClass = icon.classList[1];
                icon.classList.remove(currentIconClass);
                
                if ((item.innerText === activeMenu.text)) {
                    item.classList.add('e-active');
                    if (this.isOtherCustomer) {
                        if (currentIconClass.includes('-inactive')) {
                            currentIconClass = currentIconClass.replace('inactive', 'active');
                        }
                        else {
                            currentIconClass = currentIconClass.replace('inactive-light', 'active');
                        }
                    }
                    else {
                        currentIconClass = currentIconClass.replace('inactive', 'active');
                    }
                }
                else {
                    item.classList.remove('e-active');
                    if (this.isOtherCustomer) {
                        if (currentIconClass.includes('-inactive')) {
                            currentIconClass = currentIconClass.replace('-inactive', '-inactive-light');
                        }
                        else {
                            currentIconClass = currentIconClass.replace('-active', '-inactive-light');
                        }
                    }
                    else  {
                        currentIconClass = currentIconClass.replace('-active', '-inactive');
                    }
                }

                icon.classList.add(currentIconClass);
            })

        }, 300);
    }

    accountMenuTemplate(data) {
        return (
            data.values && data.values.length > 0 ? 
            <div className="user-name">{data.name}</div>
            :
            <div className="e-card">
                <div className="e-card-header">
                    <div className="e-card-header-caption">
                        <div className="e-card-header-title user-name small">{data.name}</div>
                        <div className="clickable c-link">
                            <span onClick={() => this.props.history.push('/min-konto/skift-adgangskode')}>Skift Adgangskode</span>
                        </div>
                    </div>
                </div>
                <div className="e-card-actions">
                    <button className="e-btn e-primary" style={{ pointerEvents: 'auto', paddingTop: 7 }} onClick={this.onLogout}>Log ud</button>
                    {/* <div>
                        <a href="">Administrere Kontoindstillinger</a>
                    </div> */}
                </div>
            </div>
        )
    }

    searchFocused() {
        this.autoCompleteRef.focus()
    }

    onSearchChange(args, value) {
        let { searchString } = this.state;
        searchString = value;
        this.setState({ searchString }, () => {
            if (searchString && searchString.length >= 3) {
                if (this.siteWideSearchTimer) {
                    clearTimeout(this.siteWideSearchTimer);
                }

                this.siteWideSearchTimer = setTimeout(async () => {
                    const payload = {
                        Context: this.searchContextRef.value,
                        Keyword: searchString
                    };

                    let result = await MenuService.SearchSite(parseInt(this.CustomerId), payload, source.token);
                    if (!result.HasError) {
                        if (result.length <= 0) {
                            result = [{ Name: "Ingen data fundet", Key: -1 }];
                        }

                        this.setState({ searchItems: result }, () => {
                            let searchInput = document.getElementsByClassName('search-input')[0];
                            if (searchInput) {
                                if (result.length > 0) {
                                    searchInput.style.borderBottomRightRadius = '0px';
                                    searchInput.style.borderTopRightRadius = '0px';
                                }
                                else {
                                    searchInput.style.borderBottomRightRadius = '50px';
                                    searchInput.style.borderTopRightRadius = '50px';
                                }
                            }
                        });
                    }
                }, 1000);
            }
            else {
                this.setState({ searchItems: [] });
            }
        });
    }

    searchMenuVisibilityChange = (args) => {

    }

    onSearchSelect(value, item) {
        if (item.Key === -1) return;
        let entity = this.entityTypes.find(e => e.Value === item.EntityType);
        let redirectTo = getPage(entity, item.ParentId);
        this.props.history.push(redirectTo.routeUrl);
        this.setState({ searchString: value }, () => {
            let searchInput = document.getElementsByClassName('search-input')[0];
            if (searchInput) {
                searchInput.style.borderBottomRightRadius = '50px';
                searchInput.style.borderTopRightRadius = '50px';
            }
        });
    }

    render() {
        const { location } = this.props;
        const { loading, searchString, searchItems, searchContext, siteRoutes } = this.state;

        if (loading) {
            return ( <Loading /> )
        }

        this.setActiveMenu(location.pathname);

        let body = document.body.getBoundingClientRect();
        let openSidebar = true;
        if (body) {
            if (body.width <= 1500) {
                openSidebar = false;
            }
        }

        return (
            <AppContext.Provider value={{
                    logger: logger,
                    siteRoutes: siteRoutes
                }}>
                    <div className="app-main">
                        <SidebarComponent ref={sidebar => this.sidebar = sidebar} id="sidebar-menu" type="Push" isOpen={openSidebar} enableDock={true} width="324px" target=".main-content-wrapper" enableGestures={false}>
                            {/* <TreeViewComponent ref={treeview => this.menuTreeView = treeview} fields={this.menuFields} expandOn="Click"
                                cssClass="sidebar-tree-menu" nodeSelected={this.nodeMenuSelected.bind(this)} />  */}
                            <MenuComponent ref={scope => this.menuObj = scope} items={this.dataMenuItems} orientation='Vertical' 
                                cssClass="sidebar-tree-menu" select={this.nodeMenuSelected.bind(this)} fields={{text: ['text',], children: ['items'] }} />
                        </SidebarComponent>
                        <div className="header">
                            <ul className="header-list">
                                <li className="sidebar-toggle list" onClick={this.toggleSidebar}><div className="sidebar-toggle--menu"></div></li>
                                <li className="app-logo icon list"><img src={companyLogo} alt="logo" /></li>
                                <li className={`list global-search ${this.isOtherCustomer ? 'acc-mod' : ''}`}>
                                    {/* <input ref={ref => this.searchFieldRef = ref} type="text" placeholder="Søg" className="search-input" value={searchString} onChange={this.onSearching}></input> */}
                                    {/* <AutoCompleteComponent ref={acc => this.globalSearchRef = acc} dataSource={[]} placeholder="Søg" className="search-input" change={this.onSearchChange} 
                                        select={this.onProductSelected} autofill={true} fields={this.autoCompleteFields} /> */}
                                    
                                    <div className="context-selection">
                                        <DropDownListComponent dataSource={this.searchContexts} ref={ref => this.searchContextRef = ref} value={searchContext} fields={this.searchContextFields} change={this.onSearchChange} />
                                    </div>
                                    <div className="context-input">
                                        <AutoComplete ref={ref => this.autoCompleteRef = ref} placeholder="Søg" onChange={this.onSearchChange} value={searchString} getItemValue={(item) => item.Name}
                                            onSelect={this.onSearchSelect} items={searchItems} onMenuVisibilityChange={this.searchMenuVisibilityChange}
                                            inputProps={{
                                                placeholder: 'Søg',
                                                className: `search-input`,
                                                // onblur: () => {
                                                //     this.autoCompleteRef.open
                                                // }
                                            }}
                                            menuStyle={{
                                                fontSize: 13,
                                                backgroundColor: "white",
                                                zIndex: 2000,
                                                left: 149,
                                                position: 'absolute',
                                                visibility: searchItems.length > 0 ? 'visible' : 'hidden',
                                                maxHeight: 550,
                                                overflowY: 'auto'
                                            }}
                                            renderItem={(item, isHighlighted) => {
                                                    let entity = this.entityTypes.find(e => e.Value === item.EntityType);
                                                    return (
                                                        <div className="autocomplete-item" style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                                            <span className="search-value">{item.Name}</span><span className="entity-type">{entity ? entity.Key : ''}</span>
                                                        </div>
                                                    )
                                                }
                                            }
                                        />
                                        <div className="c-icon c-icon-search clickable" onClick={this.searchFocused}></div>
                                    </div>
                                </li>
                                <li className="header-account-info list">
                                    <div className="horizontal-menu">
                                        <MenuComponent items={this.AccountMenuItem} cssClass='dock-menu e-template-menu' 
                                        template={this.accountMenuTemplate} fields={{text: ['text'], children: ['values'] }}></MenuComponent>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="main-content-wrapper">
                            <div className="content">
                                <ErrorBoundary>
                                    <Switch>
                                        <Route exact path={`/beskeder`} component={Messages} />
                                        <Route exact path={`/min-konto`} component={MyAccount} />
                                        <Route exact path={`/kunder`} component={Customer} />
                                        <Route exact path={`/system-indstillinger/grupper/kundegruppe`} component={SystemGroups} />
                                        <Route exact path={`/bruger-liste`} component={UserList} />
                                        <Route exact path={`/brugerkort/add`} component={UserCard} />
                                        <Route exact path={`/brugerkort/edit/:id`} component={UserCard} />
                                        <Route exact path={`/system-indstillinger/grupper/brugergrupper`} component={SystemGroups} />
                                        <Route exact path={`/produktkort/add`} component={Product} />
                                        <Route exact path={`/produktkort/edit/:id`} component={Product} />
                                        <Route exact path={`/oilmat-produkt`} component={OilmatProduct} />
                                        <Route exact path={`/produktliste`} component={ProductList} />
                                        <Route exact path={`/system-indstillinger/grupper/produkt-grupper`} component={SystemGroups} />
                                        <Route exact path={`/fil-arkiv`} component={FileArchive}/>
                                        <Route exact path={`/system-indstillinger/enheder`} component={Units} />
                                        <Route exact path={`/leverandor/leverandorliste`} component={SupplierList} />
                                        <Route exact path={`/leverandor/add`} component={Supplier} />
                                        <Route exact path={`/leverandor/edit/:id`} component={Supplier} />
                                        <Route exact path={`/system-indstillinger/vægt`} component={Weight} />
                                        <Route exact path={`/system-indstillinger/integration`} component={Integration} />
                                        <Route exact path={`/automatliste`} component={MachineList} />
                                        <Route exact path={`/automater/configurer/add`} component={Machine} />
                                        <Route exact path={`/automater/configurer/edit/:id`} component={Machine} />
                                        <Route exact path={`/indkobsordrer`} component={PurchaseList} />
                                        <Route exact path={`/indkobs-indstillinger`} component={PurchaseSetting} />
                                        <Route exact path={`/indkob/ordre-detaljer/add`} component={PurchaseOrder} />
                                        <Route exact path={`/indkob/ordre-detaljer/edit/:id`} component={PurchaseOrder} />
                                        <Route exact path={`/rapporter/bruger-defineret`} render={(props) => (<UserDefinedReports {...props} updateUserMenu={this.updateUserMenu} /> )} />
                                        <Route exact path={`/rapporter/produkt`} render={(props) => (<Reports {...props} updateUserMenu={this.updateUserMenu} /> )} />
                                        <Route exact path={`/rapporter/indkøb`} render={(props) => (<Reports {...props} updateUserMenu={this.updateUserMenu} /> )} />
                                        <Route exact path={`/rapporter/brugere`} render={(props) => (<Reports {...props} updateUserMenu={this.updateUserMenu} /> )} />
                                        <Route exact path={`/rapporter/leverandører`} render={(props) => (<Reports {...props} updateUserMenu={this.updateUserMenu} /> )} />
                                        <Route exact path={`/rapporter/kunder`} render={(props) => (<Reports {...props} updateUserMenu={this.updateUserMenu} /> )} />
                                        <Route exact path={`/rapporter/automater`} render={(props) => (<Reports {...props} updateUserMenu={this.updateUserMenu} /> )} />
                                        <Route exact path={`/rapporter/lager`} render={(props) => (<Reports {...props} updateUserMenu={this.updateUserMenu} /> )} />
                                        <Route exact path={`/rapporter/forbrug`} render={(props) => (<Reports {...props} updateUserMenu={this.updateUserMenu} /> )} />
                                        <Route exact path={`/rapporter/favoritter`} render={(props) => (<Reports {...props} updateUserMenu={this.updateUserMenu} /> )} />
                                        <Route exact path={`/etiketter`} component={Labels} />
                                        <Route exact path={`/etiketter/printer`} component={LabelPrinter} />
                                        <Route exact path={`/system-indstillinger/mailindstillinger`} component={MailSettings}/>
                                        <Route exact path={`/system-indstillinger/oilmat-integration`} component={OilmatIntegrationSettings}/>
                                        <Route exact path={`/min-konto/skift-adgangskode`} component={ChangePassword}/>
                                        <Route exact path={`/system-indstillinger/registres`} component={Register} />
                                        <Route exact path={`/system-indstillinger/registres/branche-kategori`} component={Register} />
                                        <Route exact path={`/system-indstillinger/grupper/ansvars-grupper`} component={SystemGroups} />
                                        <Route exact path={`/system-indstillinger/grupper/omkostnings-grupper`} component={SystemGroups} />
                                        <Route exact path={`/video-arkiv`} component={VideoArchive} />
                                        <Route exact path={`/værktøjer/brugere`} component={UserTools} />
                                        <Route exact path={`/værktøjer/produkter/masse-redigering`} component={ProductTools} />
                                        <Route exact path={`/værktøjer/produkter/lager-regulering`} component={StockRegulationTools} />
                                        <Route exact path={`/værktøjer/import-export`} component={ImportExportTool} />
                                        <Route exact path={`/help/:system`} component={HelpArticles} />
                                        <Route exact path={`/system-indstillinger/oilmat/sw`} component={OilmatVersions} />
                                        <Route exact path={`/system-indstillinger/oilmat/hubFw`} component={OilmatVersions} />
                                        <Route exact path={`/system-indstillinger/oilmat/fw`} component={OilmatVersions} />
                                        {/* <Route exact path={`/central-module-action-processor`} component={}/> */}
                                        <Route exact path={`/error/no-permission`} component={NoPermission} />
                                        <Redirect path="/" to="/beskeder" />
                                        <Route exact path='*' component={NotFound} />
                                    </Switch>
                                </ErrorBoundary>
                            </div>
                        </div>
                        <ToastContainer />
                    </div>
            </AppContext.Provider>
        );
    }
}

export default Main;