import React, { Component } from 'react';
import { extend } from '@syncfusion/ej2-base';
import { Query } from '@syncfusion/ej2-data';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

import regionService from '../../services/RegionService';
import CustomerGroupService from '../../services/Customer/CustomerGroupService';
import Axios from 'axios';
import Localization from '../../utilities/Localization';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class CustomerDetail extends Component {
    constructor(props) {
        super(props);

        this.state = extend({}, { loading: true }, props, true);

        this.regionSource = []
        this.postalCodes = [];
        this.cities = [];
        this.regions = [];
        this.customerGroups = [];

        this.onChange = this.onChange.bind(this);
        this.onPostalCodeChange = this.onPostalCodeChange.bind(this);
        this.onCityChange = this.onCityChange.bind(this);
        this.onRegionChange = this.onRegionChange.bind(this)
        this.onCustomerGroupChange = this.onCustomerGroupChange.bind(this);

        this.onPostalCodeFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Value', 'startswith', e.text, true) : query;
            e.updateData(this.postalCodes, query)
        }

        this.onCityFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('ShortName', 'startswith', e.text, true) : query;
            e.updateData(this.cities, query)
        }

        this.onRegionFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            e.updateData(this.regions, query)
        }
    }

    async componentDidMount() {
        let data = this.state;
        const { customerGroupId, customers } = this.props;
        // Get city and region
        const regions = await regionService.GetRegion(source.token);
        this.regionSource = regions;

        let tempRegions = [], tempCities = [];

        regions.forEach(region => {
            tempRegions.push(region);

            region.Cities.forEach(city => {

                // if (tempCities.some(x => x.ShortName === city.ShortName)) 
                //     return;

                tempCities.push(city);

                city.PostalCodes.forEach(postal => {
                    this.postalCodes.push(postal);
                })
            });
        });

        tempRegions = [...new Set(tempRegions)];
        tempCities = [...new Set(tempCities)];

        tempRegions.sort((a,b) => (a.value > b.value) ? 1 : -1);
        tempCities.sort((a,b) => (a.value > b.value) ? 1 : -1);

        this.cities = tempCities;
        this.regions = tempRegions;

        let customerGroups = await CustomerGroupService.GetCustomerGroups(source.token);
        const parentCustomerGroup = Object.assign({}, customerGroups.find(x => x.Id === customerGroupId));
        customerGroups = customerGroups.filter(x => x.Level > parentCustomerGroup.Level);
        customerGroups.sort((a,b) => (a.Level > b.Level) ? 1 : -1);
        
        this.customerGroups = customerGroups.map(x => {
            return {
                text: x.Name,
                Id: x.Id
            }
        });
        
        let tempDistributors = [], tempWholesalers = [];
        tempDistributors = customers.filter(x => x.CustomerGroupId === 2);
        tempWholesalers = customers.filter(x => x.CustomerGroupId === 3);
        
        this.distributors = tempDistributors.length <= 0 ? [] : tempDistributors.map(d => {
            return {
                Id: d.Id,
                Name: d.FullName
            }
        });
        this.wholesalers = tempWholesalers.length <= 0 ? [] : tempWholesalers.map(d => {
            return {
                Id: d.Id,
                Name: d.FullName
            }
        });

        this.setState({ loading: false }, () => {
            let form = document.getElementsByClassName('customer-detail-form')[0];
            let footerContent = form.parentElement.parentElement.parentElement.parentElement.querySelector('.e-footer-content');
            footerContent.querySelectorAll('button').forEach(elem => {
                elem.classList.remove('e-flat');
            });
            
            if (data.Id) {
                let departmentForm = document.getElementsByClassName('customer-detail-form')[0];
                if (departmentForm) {
                    let dlgCont = departmentForm.closest('.e-dlg-content');
                    if (dlgCont) {
                        dlgCont.style.paddingRight = '0px';
                    }

                    let dialogContent = departmentForm.closest('.e-dlg-content').parentElement;

                    let header = dialogContent.getElementsByClassName("e-dlg-header-content")[0];
                    if (header) {
                        header.querySelector('div.e-dialogHeaderTemplate h1').innerHTML = "Rediger Kunde";
                    }
                }
            }

            if (this.props.customerGroupId === 2) {
                this.distributorRef.enabled = false;
            }
            if (this.props.customerGroupId === 3) {
                this.wholesalerRef.enabled = false;
            }
        });
    }

    onChange(args, name) {        
        let input = args.container.querySelector('input');
    
        if (input) {
            let key = input.name;
            let value = args.value;
            this.setState({ [key]: value });
        }
    }

    onCvrChange(args) {
        this.setState({ VatNo: args.value });
    }

    onPostalCodeChange(args) {
        if (args.isInteracted) {
            let city = this.cities.find(c => c.Id === args.itemData.CityId);
            // this.cities = tempCities;
            // this.ddlCityRef.text = null;
            // this.ddlCityRef.dataSource = tempCities;
            // this.ddlCityRef.dataBind();

            this.ddlCityRef.value = args.itemData.CityId;

            let tempRegions = this.regions.filter(region => region.Id === city.RegionId);
            let selectedRegion = this.regionSource.find(x => x.Id === city.RegionId);

            this.ddlRegionRef.dataSource = tempRegions;
            this.ddlRegionRef.dataBind();

            this.ddlRegionRef.value = city.RegionId;
            this.ddlRegionRef.text =  selectedRegion.Name;
            
            this.setState({ PostalCodeId: args.value, CityId: args.itemData.CityId, RegionId: city.RegionId });
        }
    }

    onCityChange(args) {
        if (args.isInteracted) {
            let { PostalCodeId } = this.state;
            let tempRegions = this.regionSource.filter(region => region.Id === args.itemData.RegionId);    
            // this.regions = tempRegions;
            // this.ddlRegionRef.text = null;
            // this.ddlRegionRef.dataSource = tempRegions;
            // this.ddlRegionRef.dataBind();
            this.ddlRegionRef.text = null;
            this.ddlRegionRef.dataSource = tempRegions;
            this.ddlRegionRef.dataBind();
            
            if (PostalCodeId !== args.itemData.PostalCodes[0].Id) {
                PostalCodeId = args.itemData.PostalCodes[0].Id;
            }

            let selectedRegion = this.regions.find(x => x.Id === args.itemData.RegionId);
            this.ddlRegionRef.value = args.itemData.RegionId;
            this.ddlRegionRef.text = selectedRegion.Name;

            this.setState({ CityId: args.value, RegionId: args.itemData.RegionId, PostalCodeId });
        }
    }

    onRegionChange(args) {
        if (args.isInteracted) {
            this.setState({ RegionId: args.value });
        }
    }

    onCustomerGroupChange(args) {
        if (args.value) {
            this.setState({ CustomerGroupId: args.value });
        }

        // 1 - ILX, 2 - Distributor, 3 - Wholesaler, 4 - Enduser

        if (args.value === 2) {
            this.distributorRef.enabled = false;
            this.distributorRef.value = '';
            this.distributorRef.text = '';
            
            this.wholesalerRef.enabled = false;
            this.wholesalerRef.value = '';
            this.wholesalerRef.text = '';
        }
        else if (this.props.customerGroupId === 2) {
            this.distributorRef.enabled = false;
            this.distributorRef.value = '';
            this.distributorRef.text = '';
            
            this.wholesalerRef.enabled = false;
            this.wholesalerRef.value = '';
            this.wholesalerRef.text = '';
        }
        // else {
        //     this.distributorRef.enabled = true;
        //     this.distributorRef.value = '';
        //     this.distributorRef.text = '';
            
        //     this.wholesalerRef.enabled = true;
        //     this.wholesalerRef.value = '';
        //     this.wholesalerRef.text = '';
        // }

        if (args.value === 3) {
            this.wholesalerRef.enabled = false;
            this.wholesalerRef.value = '';
            this.wholesalerRef.text = '';
            
            this.distributorRef.enabled = true;
            this.distributorRef.value = '';
            this.distributorRef.text = '';
        }
        else if (this.props.customerGroupId === 3) {
            this.wholesalerRef.enabled = false;
            this.wholesalerRef.value = '';
            this.wholesalerRef.text = '';
            
            this.distributorRef.enabled = true;
            this.distributorRef.value = '';
            this.distributorRef.text = '';
        }
        // else {
        //     this.wholesalerRef.enabled = true;
        //     this.wholesalerRef.value = '';
        //     this.wholesalerRef.text = '';
        // }

        if (args.value === 4) {
            this.distributorRef.enabled = false;
            this.distributorRef.value = '';
            this.distributorRef.text = '';
            this.wholesalerRef.enabled = true;
            this.wholesalerRef.value = '';
            this.wholesalerRef.text = '';
        }
        else if (this.props.customerGroupId === 4) {
            this.distributorRef.enabled = false;
            this.distributorRef.value = '';
            this.distributorRef.text = '';
            this.wholesalerRef.enabled = true;
            this.wholesalerRef.value = '';
            this.wholesalerRef.text = '';
        }
        // else {
        //     this.distributorRef.enabled = true;
        //     this.distributorRef.value = '';
        //     this.distributorRef.text = '';
        // }
    }

    onDropdownChange(name, args) {
        if (args.value) {
            this.setState({ [name]: args.value });
        }
    }

    render() {
        let data = this.state;
        
        if (data.loading) {
            return <div style={{margin: '20px 0'}}></div>
        }

        return (
            <div className="form customer-detail-form">
                <div className="form-title"><h2>Kunde Detaljer</h2></div>
                <div className="form-row">
                    <div className="form-group form-col">
                        <TextBoxComponent value={data.CustomerNo} placeholder="Konto Nr." name="CustomerNo" disabled={true} change={this.onChange} floatLabelType="Auto" />
                    </div>
                    <div className="form-group form-col">
                        <TextBoxComponent value={data.CompanyName} placeholder="Firma navn*" name="CompanyName" change={this.onChange} floatLabelType="Auto" autocomplete='off' />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group form-col">
                        <TextBoxComponent value={data.Street} placeholder="Adresse" name="Street" change={this.onChange} floatLabelType="Auto" autocomplete='off' />
                    </div>
                    <div className="form-group form-col">
                        {/* <TextBoxComponent value={data.ZipNo} placeholder="Post nr" name="ZipNo" change={this.onChange.bind(this)} floatLabelType="Auto" /> */}
                        <DropDownListComponent id="ddlPostalCodeId" ref={ref => this.ddlPostalCodeRef = ref} name="PostalCodeId" value={data.PostalCodeId} dataSource={this.postalCodes} filtering={this.onPostalCodeFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchPostalCode} 
                            allowFiltering={true} placeholder="Post nr" fields={{ text: 'Value', value: 'Id' }} change={this.onPostalCodeChange} floatLabelType="Auto" locale="da" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group form-col">
                        <DropDownListComponent id="ddlCity" ref={ref => this.ddlCityRef = ref} name="CityId" value={data.CityId} dataSource={this.cities} filtering={this.onCityFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchCity}
                        allowFiltering={true} placeholder="By" fields={{ text: 'ShortName', value: 'Id' }} change={this.onCityChange} floatLabelType="Auto" locale="da" />
                    </div>
                    <div className="form-group form-col">
                        <DropDownListComponent id="ddlRegion" ref={ref => this.ddlRegionRef = ref} name="RegionId" value={data.RegionId} dataSource={this.regions} filtering={this.onRegionFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchRegion}
                        allowFiltering={true} placeholder="Region" fields={{ text: 'Name', value: 'Id' }} change={this.onRegionChange} floatLabelType="Auto" locale="da" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group form-col">
                        <TextBoxComponent value={data.Country} placeholder="Land" name="Country" change={this.onChange} floatLabelType="Auto" />
                    </div>
                    <div className="form-group form-col">
                        <TextBoxComponent value={data.PhoneNumber} placeholder="Tlf . Nr." name="PhoneNumber" change={this.onChange} floatLabelType="Auto" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group form-col">
                        <TextBoxComponent value={data.Email} placeholder="Mail*" name="Email" change={this.onChange} floatLabelType="Auto" />
                    </div>
                    <div className="form-group form-col">
                        <NumericTextBoxComponent showSpinButton={false} value={data.VatNo} placeholder="Cvr. Nr.*" format="###" name="VatNo" change={this.onCvrChange} floatLabelType="Auto" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group form-full-col">
                        <DropDownListComponent id="ddlCustomerGroup" name="CustomerGroupId" value={data.CustomerGroupId} dataSource={this.customerGroups} locale="da"
                        placeholder="Kunde Grupper*" fields={{ text: 'text', value: 'Id' }} change={this.onCustomerGroupChange} floatLabelType="Auto" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group form-full-col">
                        <DropDownListComponent id="ddlDistributor" name="DistributorId" ref={ref => this.distributorRef = ref} value={data.DistributorId} dataSource={this.distributors} locale="da"
                        placeholder="Vælg Distributør" fields={{ text: 'Name', value: 'Id' }} change={this.onDropdownChange.bind(this, "DistributorId")} floatLabelType="Auto" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group form-full-col">
                        <DropDownListComponent id="ddlWholesaler" name="WholesalerId" ref={ref => this.wholesalerRef = ref} value={data.WholesalerId} dataSource={this.wholesalers} locale="da"
                        placeholder="Vælg grossist" fields={{ text: 'Name', value: 'Id' }} change={this.onDropdownChange.bind(this, "WholesalerId")} floatLabelType="Auto" />
                    </div>
                </div>
                <div className="form-title"><h2>Konto Detaljer</h2></div>
                <div className="form-row">
                    <div className="form-group form-col">
                        <TextBoxComponent value={data.Username} placeholder="Brugernavn*" name="Username" change={this.onChange} floatLabelType="Auto" />
                    </div>
                    <div className="form-group form-col">
                        <TextBoxComponent value={data.FirstName} placeholder="Fornavn*" name="FirstName" change={this.onChange} floatLabelType="Auto" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group form-col">
                        <TextBoxComponent value={data.MiddleName} placeholder="Mellem navn" name="MiddleName" change={this.onChange} floatLabelType="Auto" />
                    </div>
                    <div className="form-group form-col">
                        <TextBoxComponent value={data.LastName} placeholder="Efternavn*" name="LastName" change={this.onChange} floatLabelType="Auto" />
                    </div>
                </div>
            </div>
        )
    }
}

export default CustomerDetail;
