import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import MessageHelper from '../utilities/MessageHelper';

export class NavigationPrompt extends Component {
    constructor() {
        super();

        this.state = {
            modalVisible: false,
            lastLocation: null,
            confirmedNavigation: false
        };

        this.productCustomButtons = [
            {
                click: () => this.handleConfirmNavigationClick(),
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                click: () => {
                    const { navigateToField } = this.props;
                    this.closeModal(navigateToField);
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'OK'
                }
            }
        ];

        this.buttons = [
            {
                click: () => this.handleConfirmNavigationClick(),
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                click: () => {
                    const { initiateSave } = this.props;
                    this.closeModal(initiateSave);
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'Gem'
                }
            }
        ]
        this.animationSettings = { effect: 'None' };
    }

    showModal = (location) => this.setState({ modalVisible: true, lastLocation: location })
    closeModal = (callback) => this.setState({ modalVisible: false }, callback)

    handleBlockedNavigation = (nextLocation) => {
        const { confirmedNavigation } = this.state;
        const { shouldBlockNavigation } = this.props;
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            this.showModal(nextLocation);
            return false;
        }

        return true;
    }

    handleConfirmNavigationClick = () => this.closeModal(() => {
        const { navigate, onCancelSave } = this.props;
        const { lastLocation } = this.state;
        if (lastLocation) {
            this.setState({ confirmedNavigation: true }, () => {
                if (onCancelSave) {
                    onCancelSave();
                }
                
                navigate(lastLocation.pathname);
            })
        }
    })

    render() {
        const { when, customMessage, customHeader, moduleKey } = this.props;
        const { modalVisible, lastLocation } = this.state;

        let content = customMessage || MessageHelper.MB1.message;
        let header = customHeader || "Advarsel";
        let buttons = null;

        if (moduleKey === "product-add") {
            buttons = this.productCustomButtons;
        }
        else {
            buttons = this.buttons;
        }
        
        return (
            <React.Fragment>
                <Prompt when={when} message={this.handleBlockedNavigation} />
                <DialogComponent showCloseIcon={true} animationSettings={this.animationSettings} visible={modalVisible} isModal={true} showCloseIcon={false}
                    target="body" header={header} buttons={buttons} content={content} cssClass="dialog-notification" width="450px"></DialogComponent>
            </React.Fragment>
        )
    }
}

export default NavigationPrompt
